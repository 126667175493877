<template>
    <div class="updata_box">
        <div style="width: 100%;height: 50px;"></div>
        <div class="updata_box_cenete" v-if="isactive === 1">
            <div class="updata_div">
                <div class="data_div_top">
                    <div class="data_div_img">
                        <el-image style="width: 100%; height: 100%" :src="fileList.cover" fit="contain"></el-image>
                    </div>
                    <div class="data_div_txt">
                        <div class="txt1">
                            {{ fileList.artworkTitle }}
                        </div>
                        <div class="txt2">
                            <div>作者：{{ fileList.authorName }}</div>
                            <div>票数：{{ fileList.vote || 0 }}</div>
                        </div>
                        <div class="txt3">
                            <div>编号：{{ fileList.id }}</div>
                            <div style="color: #A6A6A6;">浏览量：{{ fileList.browse }}</div>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;" v-if="fileList.video">
                    <div class="data_div_voide">
                        <video style="width: 100%;" controls="true" :src="fileList.video"></video>
                    </div>
                </div>
                <div class="data_div_content">{{ fileList.artworkClarification }}</div>
                <div class="data_div_imglist" v-if="urls.length > 0">
                    <el-image style="width: 100%;" v-for="url in urls" :key="url" :src="url" lazy></el-image>
                </div>
            </div>
        </div>
        <div style="width: 100%;height: 50px;"></div>
        <img class="updata_bottom" src="../../../../static/wenchang/4.png" alt="">
    </div>
</template>

<script>
import { artworkselectOne, artworkbrowse } from "../../../util/api"
export default {
    name: 'science_child2',

    data() {
        return {
            fileList: {},
            isactive: 1,
            urls: [],
            artworkId: '',
            liu: '',
        }
    },
    mounted() {
        this.getdata()
    },
    created() {
        this.artworkId = this.$route.query.id
        this.liu = this.$route.query.liu
        this.liulang()
    },
    methods: {
        liulang() {
            if (this.liu) {
                artworkbrowse(this.artworkId).then((res) => {

                })
            }
        },
        getdata() {
            const id = this.$route.query.id
            artworkselectOne({
                id: id
            }).then((res) => {
                console.log(res);
                this.fileList = res.data
                if (res.data.pictures) {
                    this.urls = res.data.pictures.split(',')
                }
            })
        },
        personal(val) {
            this.isactive = val
        }
    }
}
</script>
<style scoped>
.submits {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 50px;
}

.submit {
    width: 100%;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 50px;
    cursor: pointer;
}

.updata_bottom {
    /* position: fixed;
    bottom: 0 */
    width: 100%;
    margin-bottom: -6px;
}

.updata_box_cenete {
    margin: 61px auto;
    width: 1061px;
    min-height: 1100px;
    background: #FFFFFF;
    border-radius: 30px;
}

.updata_box {
    background-image: url("../../../../static/wenchang/8.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.updata_div {
    position: relative;
    padding: 0 71px;
    padding-top: 51px;
    width: 100%;
    height: 100%;
}

.updata_div_toptext {
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    color: #333333;
}

.updata_div_toptext2 {
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    color: #333333;
}

.input1 {
    width: 100%;
    display: flex;
    align-items: center;
}

.input2 {
    width: 100%;
    display: flex;
    align-items: center;
}

.input3 {
    width: 100%;
    display: flex;
}

.select1 {
    display: flex;
    align-items: center;
}

.select2 {}

.input3uplodtxt {
    font-weight: 100;
    font-size: 14px;
    color: #F34242;
    margin-top: 20px;
    margin-left: 152px;
}

.el-upload__tip {
    font-size: 14px;
    color: #F34242;
}

.personal {
    width: 225px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 20px;
    background: #FFFFFF;
    color: #187AC2;
    border-radius: 10px;
    margin: 26px 0;
}

.personala {
    width: 225px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 20px;
    background: #187AC2;
    color: #FFFFFF;
    border-radius: 10px;
    margin: 26px 0;
}

.personal_box {
    position: absolute;
    top: 7%;
    left: 7%;
}

@media (max-width: 480px) {}

@media (max-width: 5000px) {
    .data_div_voide {
        margin: 50px auto;
        width: 720px;
        /* height: 400px; */
    }

    .data_div_top {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding: 20px;
        padding-bottom: 20px;
    }

    .data_div_img {
        width: 300px;
        height: 100%;
    }

    .data_div_txt {
        width: 50%;
    }

    .txt1 {
        width: 100%;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 23px;
        color: #333333;
        padding: 15px;
    }

    .txt2 div {
        width: 50%;
    }

    .txt3 div {
        width: 50%;
    }

    .txt2 {
        width: 100%;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #0278CF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
    }

    .txt3 {
        width: 100%;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #0278CF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
    }

    .data_div_content {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        padding: 30px;
    }

    .data_div_imglist {
        padding: 30px;
    }
}
</style>